/***************************************************************************************************************************
 * APPLICATION **************************************************************************************************************
 *****************************************************************************************************************************/
$('<button class="close_modal" onclick="hideAchatExpress.call(this);"></button>').appendTo(".achat_express");

/*/ Add search bar icon in menu mobile top /*/
const searchTemplate = `
    <div class="header_section">
        <div id="search_icon" class="banner_itm search_icon" onclick="showhide_search()">
            <span class="vector_sprite"></span>
        </div>
    </div>
`;

$(searchTemplate).insertBefore('header .banner_nav .header_section.logo');

/*/ Toggle new search bar v2/*/
function showhide_search() {
    const topSearchSelector = $('#top_search');
    const shadeSelector = $('#shade');
    const bodySelector = $('body');
    const accountMenuSelector = $('#account_menu');

    const activateSearch = () => {
        shadeSelector.addClass('visible');
        shadeSelector.css('z-index', '25');
        bodySelector.removeClass('search_hidden');
        topSearchSelector.addClass('activated');
    }

    const deactivateSearch = () => {
        shadeSelector.removeClass('visible');
        bodySelector.addClass('search_hidden');
        topSearchSelector.removeClass('activated');
    }

    topSearchSelector.slideToggle().toggleClass('activated');
    bodySelector.toggleClass('searching');

    const isTopSearchActivated = topSearchSelector.hasClass('activated');

    isTopSearchActivated ? activateSearch() : deactivateSearch();

    accountMenuSelector.hasClass('active') ? accountMenuSelector.removeClass('active') : null;
}

// Close recherche mobile by shade
$(document).on('click', '#shade.visible', function () {
    $("body").removeClass("noscroll");
    $("body").removeClass("searching");
    $("#top_search").hide();
    $("#top_search").removeClass("activated");
    $("#shade").removeClass('visible');
    $(".homepage").addClass('search_hidden');
});

// Close search bar when clicking on main menu button
$(document).on('click', '#main_menu_btn, .filters_cmd', function () {
    if ($("#top_search").hasClass("activated")) {
        $("body").removeClass("noscroll");
        $("body").removeClass("searching");
        $("#top_search").hide();
        $("#top_search").removeClass("activated");
        $(".homepage").addClass('search_hidden');
    }
});

$(window).on("displayFullLoader", function () {

    $('#shade').addClass('withLoader');
    $('#shade').addClass('actif');
});

$(window).on("hideFullLoader", function () {

    $('#shade').removeClass('withLoader');
    $('#shade').removeClass('actif');
});

// v2 code
function toggleViewMobile() {
    $('div.toggleNumObjects span').toggleClass('actif');
    if ($("#oneProduct").hasClass('actif')) {
        $('.twoProducts').removeClass('actif');
        $('div.item_container').addClass('full');
        createCookie('nb_produits', 1, 1);
    } else {
        $('div.item_container').removeClass('full');
        $('.oneProduct').removeClass('actif');
        createCookie('nb_produits', 2);
    }
}

// Toggles filters submenu visibility mobile
function toggleFilters() {
    if ($(this).find('.selected_options').hasClass('actif')) {
        $(this).find('.selected_options').removeClass('actif');
        $(this).find('.filter_name').removeClass('actif');
    } else {
        $('.selected_options').removeClass('actif');
        $('.filter_name').removeClass('actif');
        $(this).find('.selected_options').addClass('actif');
        $(this).find('.filter_name').addClass('actif');
    }
}

function searchV2Shad() {
    setTimeout(function () {
        $("#shade").removeClass('visible');
    }, 0);
}

$(function () {
    setTimeout(() => {
        if ($(".swiper-slide", "#sliderBandoNew").length > 1) {
            var bandoHeaderNew = new Swiper("#sliderBandoNew", {
                slidesPerView: 1,
                autoplay: true,
                loop: true,
                speed: 5000,
            });
        } else {
            var bandoHeaderNew = new Swiper("#sliderBandoNew", {
                slidesPerView: 1,
            });
        }
    }, 400);

    // // Accordion Menu
    setTimeout(function () {
        var leftMenu = "#left_menu .menu_part.bottom";
        var firstLevelTitle = leftMenu + " .first_level_item.has_submenu";
        var submenuWrapper = leftMenu + " .submenu_wrapper";
        menuTopAcc(firstLevelTitle, submenuWrapper);

        var subSubTitle = leftMenu + " .first_level_item.has_submenu + .submenu_wrapper .ss_cat li.has_submenu";
        var subSubWrapper = subSubTitle + " + .only_categ_name";
        menuTopAcc(subSubTitle, subSubWrapper);

        $("#left_menu .general_menu .menu_item a.bloc_lnk.user span").text(translate('mon_compte_titre'));
    }, 400);

    // Bandeau
    setTimeout(function () {
        if ($(".bandeauMenuAffix").length) {
            $('body').addClass('hasBando');

            $(".bandeauMenuAffix .closeBtn").on('click touch', function () {
                $(".bandeauMenuAffix").slideUp();
                $("body").removeClass('hasBando');

                setTimeout(() => {
                    adjustMenuTop();
                }, 500);
            });
        }
    }, 200);

    setTimeout(function () {
        if ($('#subtitleLogin')) {
            var title = $('#subtitleLogin').prev('h3');
            var text = title.text();

            // Remove <h3>
            title.remove();

            // Replace <h3> by <h2>
            $('<h2>'+ text + '</h2>').insertBefore($('#subtitleLogin'));
        }
    }, 250);

    replaceFullImageRayon();

    onWindowScroll();

    handleChangeBlocBank();

    /* Wrapping texte content to match mobile as original code is found on desktop view */
    $("footer .wrapper_bottom_right .wrapper_links").each(function () {
        $(this).find(".phone_contact, .informations_contact").wrapAll("<div class='content_ctn'></div>");
        $(this).find(".link_wrap").wrapAll("<div class='content_ctn'></div>");
    });

    /*** FICHE PRODUIT ***/
    /** Ouverture Fermeture Onglet Informations */
    $(".accordion_container .product_detail_title").on("click", function () {
        // Si on clique sur l'onglet ouvert => Fermeture de l'onglet
        if ($(this).parent().hasClass("actif")) {
            $(this).parent().removeClass("actif");
            $(this).siblings(".product_detail_content").removeClass("actif");

            // Si on clique sur un autre onglet => Fermeture des onglets ouverts et ouverture de l'onglet sélectionné
        } else {
            $(".product_detail").removeClass("actif");
            $(".product_detail_content").hide();
            $(this).parent().addClass("actif");
            $(this).siblings(".product_detail_content").slideDown();

            // scroll to the title which has been clicked
            var scrollOffset = $(this).offset().top - 125;
            $('html, body').animate({
                scrollTop: scrollOffset
            }, 500);
        }
    })
    // Short script to toggle used cp info
    $(".bill_line.used_cp_info").click(function () {
        $(this).toggleClass("open");
        $(".bill_line.used_cp_info span").slideToggle();
    });

    // Product zoombox icon trigger
    $('.zoomButton').on('touch click', function () {
        zoomImg();
        $('#zoomBox').addClass('active');

        $('#content_movable').addClass('visible');
        var zoomboxWidth = $('body').width(),
            scrollTo = zoomboxWidth / 2;
        setTimeout(function () {
            $('#zoomBox').scrollLeft(scrollTo);
        }, 'slow');
    });

    // close filters rayon on click shade &
    $('#shade, #filters_menu .btn_close').click(function () {
        if ($(this).hasClass('visible forMenu forFilters')) {
            toggleMenu('filters', 'right');
            // close toggle filters
            $('.filters').find('legend').removeClass('open');
            $('.filter_options').hide();
        }
    });

    /* Footer accordion */
    $("footer .wrapper_bottom_right .title_wrap").each(function () {
        $(this).click(function () {
            if ($(this).hasClass("active")) {
                $(this).removeClass("active");
                $(this).parent().find(".content_ctn").removeClass("active").hide();

            } else {
                $("footer .wrapper_bottom_right .title_wrap").removeClass("active");
                $("footer .wrapper_bottom_right .content_ctn").removeClass("active").hide();
                $(this).addClass("active");
                $(this).parent().find(".content_ctn").addClass("active").show();
            }
        });
    });

    /* Dropdown Account */
    $("#account_nav .init").on("click touch tap", function () {
        $(".item_ctn").toggleClass("active");
    });

    $("#account_nav .item_ctn .item").each(function () {
        if ($(this).hasClass("actif")) {
            var getText = $(this).find("a").text();
            $("#account_nav .init").text(getText);
        }
    });

    $('button.close_modal .close').on('touch click', function () {
        $(this).parents('.achat_express').find('.bloc_add_alert_confirmation').hide();
        $(this).parents('.achat_express').find('.alert_stock, .bloc_add_alert_form').show();
        $('#shade').removeClass('express_visible');
        $('.rollover.achat_express.displayInfoStock').hide();
    });

    $('#alert_box .button.cancel span').on('touch click', function () {
        if ($('.rollover.achat_express.displayInfoStock').is(':visible')) {
            $('#shade').addClass('express_visible');
        }
    });

    $('.satellite_politique_de_confidentialite .content_sat .cookieShowLightbox').click(function() {
        $('.satellite_politique_de_confidentialite #cookiesLightbox').addClass('displayCookies');
        if($('.satellite_politique_de_confidentialite #cookiesLightbox').has('.hideCookies')) {
            $('.satellite_politique_de_confidentialite #cookiesLightbox').removeClass('hideCookies');
        }
        $('.satellite_politique_de_confidentialite #cookiesLightbox .w-form-line.w-submit button.w-loader').addClass('hideLoader');
    });

    $('.satellite_politique_de_confidentialite #js_cookie_refuse, .satellite_politique_de_confidentialite #js_cookie_accept').click(function() {
        if($('.satellite_politique_de_confidentialite #cookiesLightbox').has('.displayCookies')) {
            setTimeout(() => {
                $('.satellite_politique_de_confidentialite #cookiesLightbox').removeClass('displayCookies');
                $('.satellite_politique_de_confidentialite #cookiesLightbox').addClass('hideCookies');
                $('.satellite_politique_de_confidentialite #shadCookie').removeClass('actif');
            }, 500);
        }
    });

    updateBasketDeleteCta();

    if ($('.rollover.achat_express').length) {
        $('.rollover.achat_express .ligne_form .form_itm.check label').removeClass('actif');

        $('.wrap_rolloverproduit .bloc_achat_express').on("click", function () {
            $('.rollover.achat_express .ligne_form .form_itm.check label').removeClass('actif');
        });

        $('.rollover.achat_express .ligne_form .form_itm.check label').on("click", function () {
            $(this).addClass('actif');
        });
    }

    if($('.page_rayon #list_item').length) {
        if(!$('.first_push').length && $('.second_push').length) {
            $('.page_rayon #list_item').addClass('new_product_order');
        }
    }

    //Cleans backslash charachter which is automatically added in input value when adress has an apostrophe
    if ($('#adresseLiv').length) {
        var currentValue = $('#adresseLiv').val();
        var cleanAntiSlash = "\\";
        var newValue = currentValue.replace(cleanAntiSlash, '');
        $('#adresseLiv').val(newValue);
    }

    if ($('div.toggleNumObjects span').length) {
        $('div.toggleNumObjects span').click(function () {
            replaceFullImageRayon();
        });
    }

    /****** PAGES MARQUES *******/
    if ($('.top_brand').length) {
        var topBrandSwiper = new Swiper(".top_brand", {
            slidesPerView: 1.1,
            spaceBetween: 10,
            simulateTouch: true,
            lazyLoading: true,
            centeredSlides: true,
        });
    }

    if ($('.wrapper_choix_alphabetique').length) {
        var alphabetSwiper = new Swiper(".marques_nav", {
            freeMode: true,
            slidesPerView: "auto",
            simulateTouch: true,
            lazyLoading: true,
        });
    }

    if ($(".cover-module .swiper-slide").length > 1) {
        var newSwiper = new Swiper(".cover-module .swiper", {
            slidesPerView: 1,
            autoplay: 5000,
            speed: 500,
            simulateTouch: true,
            loop: true,
            lazyLoading: true,
            pagination: '.swiper-pagination',
            paginationClickable: true,
        });
    }

    if ($(".social-module .swiper-slide").length > 1) {
        var newSwiper = new Swiper(".social-module .swiper", {
            slidesPerView: "auto",
            simulateTouch: true,
        });
    }

    if ($(".home-module.blog-module .swiper-slide").length > 1) {
        var newSwiper = new Swiper(".blog-module .swiper", {
            freeMode: true,
            slidesPerView: "auto",
            simulateTouch: true,
            spaceBetween: 10,
        });
    }

    /**********SWIPERS PAGES ADV************/

    if ($('.multiple-bloc-3-blocs.swiper').length) {
        var threeBlocksSwiper = new Swiper(".multiple-bloc-3-blocs.swiper", {
            slidesPerView: 1.1,
            spaceBetween: 20,
            centeredSlides: true,
            simulateTouch: true,
            lazyLoading: true,
        });
    }

    if ($('.cms-page-module.blog-module .swiper').length) {
        var blogModuleSwiper = new Swiper(".blog-module .swiper", {
            slidesPerView: 1.1,
            spaceBetween: 20,
            centeredSlides: true,
            lazyLoading: true,
        });
    }

    if ($('body.category').length || $('body.search').length) {
        replaceFullImageRayon();
    }

    setTimeout(function () {
        if ($(".swiperNetreviews2 .swiper-slide").length > 1) {
            var newSwiper = new Swiper(".swiperNetreviews2", {
                slidesPerView: 1,
                spaceBetween: 2,
                navigation: {
                    nextEl: '.netreviews-button-next',
                    prevEl: '.netreviews-button-prev',
                },
                pagination: '.swiper-scrollbar',
                paginationType: 'progress',
            });
        }
    }, 1000);

    $(".netreviews_reviews_rate").click(function () {
        $('html, body').animate({
            scrollTop: $(".nrc_customizable_wrapper").offset().top
        }, 1000);
    });

    if ($('.pushPromoBasket').length) {
        if ($('.elem_tunnel.right_side .pushBasketWrapper').length) {
            $('.elem_tunnel.right_side .pushBasketWrapper').hide();
        }
    }

    let initScrollToState = sessionStorage.getItem("infiniteScroll");

    var runOnPage = 'body.category';

    // Load mobile version
    var headerWrapperElmt = '.banner_wrapper';
    var itemContainerElmt = '#list_item .item_container';
    var resetElmt = '.menu_part.bottom a';
    var seeMoreElmt = '.pagerNav.end_pagi .loader_scroll';
    var deviceLoadDelay = 500;
    var scrollSpacer = 40;

    if ($(runOnPage).length) {
        const productLoaded = $(itemContainerElmt+':not(".push")').length;
        const maxItems = parseInt($('#totalElems').val());

        if (productLoaded < maxItems) {
            // "See all" button must be visible when loading page for first time by default
            $(seeMoreElmt).fadeTo(100, 1);
        }

        // Handle "See all" click to trigger infinite scroll
        $(seeMoreElmt).on('click', function () {
            $(this).addClass('loading');

            const productLoaded = $(itemContainerElmt+':not(".push")').length;
            const nextPage = parseInt($('#bottom_page').val()) + 1;

            if (productLoaded < maxItems) {
                paginationGoToPage(nextPage, "bottom");
            }
        });

        // Store clicked product page reference
        addClickTrigger($('#bottom_page').val());

        setTimeout(() => {
            var anchor = window.location.hash;

            if (anchor) {
                // Smooth scroll to the target element
                var targetElement = $(anchor);

                if (targetElement.length) {
                    var headerHeight = $(headerWrapperElmt).outerHeight(); // Adjust selector as needed for the header
                    var targetPosition = $(targetElement).offset().top - (headerHeight + scrollSpacer); // Calculate target position

                    // Remove the anchor from the URL after scrolling
                    history.replaceState(null, null, ' '); // This removes the hash

                    $('html, body').animate({
                        scrollTop: targetPosition
                    }, 1300); // Duration of the scroll
                }
            }
        }, deviceLoadDelay);

        if (initScrollToState === "true") {
            // Restore infinite scroll if previously present
            setTimeout(() => {
                initInfiniteScroll(true);
            }, 1000);
        }
    }

    setTimeout(() => {
        $(resetElmt).on('click touchstart', function () {
            if (!$(this).attr("onClick")) {
                // Disable scroolToProduct function
                eraseCookie('p_id');
                // Disable infinite scroll if page 1 is loaded else leave last choice on
                initInfiniteScroll(false);
            }
        });
    }, deviceLoadDelay);
});

function wishlistAddText() {
    const $quantitySelector = $('.cart_item_actions_form .product_quantity_change .selector');
    if ($quantitySelector.length && !$quantitySelector.find('.quantity').length) {
        $quantitySelector.append('<div class="quantity"></div>').find('.quantity').text(translate('quantity_in_wishlist'));
    }

    $('.cart_item_actions_content .cart_item_actions_bloc.delete button span').text(translate('empty_from_cart'));
}

function wishlistEditBloc() {
    setTimeout(function () {
        var toggle_modal_actions = $('.toggle_modal_actions');
        if (toggle_modal_actions.length > 0) {
            toggle_modal_actions.click(function () {
                showModalAction($(this));
            });
        }
    }, 100);
}

$(document).on('click', '.toggle_modal_actions', function() {
    wishlistAddText();
    wishlistEditBloc();
});

/***************************************************************************************************************************
 * FUNCTIONS ****************************************************************************************************************
 *****************************************************************************************************************************/

function handleChangeBlocBank(params) {
    $('.wrapper_bloc_banque').on('click', function (e) {
        // validate seperatly alias bank wrapper to handle actif class + payment and prevent direct click validation

        if (!$('.gift_card').hasClass('actif')) {
            // Si 'gift_card' était actif mais ne l'est plus, on déclenche le clic
            $('gifCardInvisibleInput').click();
        }

        if (!$(this).hasClass('actif')) {
            $('.wrapper_bloc_banque.actif').removeClass('actif');

            // Alias wrapper special case, delay it so the payment doesn't triggers
            if ($(this).hasClass("alias_list_wrapper")) {
                // Store $(this) in a variable to prevent losing scope
                var alias_bloc = $(this);

                setTimeout(function() {
                    // Normally add actif class on bloc but with a short delay
                    alias_bloc.addClass('actif');
                }, 300);
            } else {
                // Normally add actif class on bloc
                $(this).addClass('actif');
            }
        }
    });

    if (document.querySelectorAll('.hipay-3-alias-submit-form').length) {
        var formHipayAlias = document.querySelectorAll(".hipay-3-alias-submit-form");
        var hasSubmitted = false;

        for (var i = 0; i < formHipayAlias.length; i++) {
            formHipayAlias[i].addEventListener("submit", function (event) {
                event.preventDefault();

                var form = event.target;
                var alias_id = form.getAttribute('alias-id');
                var form_id = form.id;
                var form_submit_button = form.querySelector('#hipay-3-alias-submit-button_' + alias_id);

                // If we're on bank page
                if (event.target.closest('.wrapper_bloc_banque')) {
                    // Handle form submit + actif bank wrapper :

                    // Identify bank bloc
                    var bloc_banque = event.target.closest('.wrapper_bloc_banque');

                    // If alr
                    if (!bloc_banque.classList.contains('actif')) {
                        // Another script handles adding the actif class, so just block script for once
                        return;
                    }
                }

                // Add loading class to button in order to trigger loader elements
                form_submit_button.classList.add("loading");

                // If user has already submitted the form, kill script
                if (hasSubmitted) {
                    return true;
                }

                // Change value to true while submit is not done
                hasSubmitted = true;

                form.submit();

                // Once submit is done, set value to false
                hasSubmitted = false;
            });
        }
    }
}

/* Function to show hide search bar */
// Toggles user account menu visibility
function toggleAccountMenu() {
    var $btn = $(this),
        menu = $(document.getElementById('account_menu')),
        marker = $('.marker', menu),
        body = $('body');
    offset = this.offsetLeft + (0.5 * this.offsetWidth);

    offset += -6;

    marker.css('left', offset);

    if (menu.hasClass('active')) {
        body.removeClass('noscroll');
        $btn.add(menu).removeClass('active');

    } else {
        body.addClass('noscroll');
        $btn.add(menu).addClass('active');
    }

    setTimeout(() => {
        adjustMenuTop();
    }, 200);
}

function adjustMenuTop() {
    var getHeaderHeight = $("header.banner_wrapper").height();
    var getHeaderSearchHeight = $("header #top_search").height();
    var accountMenuHeigh = (getHeaderHeight - getHeaderSearchHeight);
    if ($("body").hasClass("homepage")) {
        $("#account_menu").css("top", accountMenuHeigh + "px");

    } else {
        $("#account_menu").css("top", getHeaderHeight + "px");
    }
}

function menuTopAcc(firstLevelTitle, submenuWrapper) {
    setTimeout(function () {
        $(submenuWrapper).hide();

        $(firstLevelTitle).each(function () {
            $(this).on('click tap touch', function (event) {
                target = $(this);
                event.preventDefault();

                if (!$(this).hasClass('active')) {
                    $(firstLevelTitle + ', ' + submenuWrapper).removeClass('active');
                    $(submenuWrapper).slideUp(300);
                    $(this).addClass('active');
                    $(this).next().addClass('active').slideDown(300);
                    setTimeout(function () {
                        if (target.hasClass('has_submenu')) {
                            openSubMenu(target);
                        }
                    }, 300);
                } else {
                    $(this).removeClass('active');
                    $(this).next().removeClass('active').slideUp(300);
                }
            });
        });
    }, 200);
}

function openSubMenu(target) {
    var id = $(target).attr('id');
    switch (id) { // Open the specific submenu
        case 'menu_phytotherapie-complements-alimentaires':
            if (!$(target).next().find('#' + id + '_par-bienfaits').hasClass('active')) {
                $(target).next().find('#' + id + '_par-bienfaits').click();
            }
            break;
        case 'menu_aromatherapie-huiles-essentielles':
        case 'menu_cosmetique-beaute-et-hygiene':
        case 'menu_herboristerie-thes-et-infusions':
        case 'menu_maison-entretien-et-ambiance':
            if (!$(target).next().find('#' + id + '_par-univers').hasClass('active')) {
                $(target).next().find('#' + id + '_par-univers').click();
            }
            break;
        default:
            //we do nothing
            break;
    }
}

function replaceFullImageRayon() {
    setTimeout(function () {
        $('div.item_container .wrapper_img img').each(function () {
            var imgSrc = $(this).attr('src');
            var mediumSrc = imgSrc.replace('prodfp', 'medium');
            var prodfpSrc = imgSrc.replace('medium', 'prodfp');

            if ($('div.item_container').hasClass('full')) {
                $(this).attr('src', prodfpSrc);
            } else if (!$('div.item_container').hasClass('full')) {
                $(this).attr('src', mediumSrc);
            }
        });
    }, 200);
}

function addFileInput2(id_input, string) {
    var filename = $('input[type="file"]').val().split('\\').pop();

    if (filename) {
        $(".notif_upload").text(filename);
        $("#reset_file").css('display', 'block');
    }
}

function resetFile(id_input) {
    if (id_input == "filepj1") {
        var file = document.getElementById('filepj1');
        var reset_file = document.getElementById('reset_file');
        reset_file.style.display = "none";
        $('.message_file .file_msg p').text('');
        file.value = '';
    }
}

/* FAQ */
function faqinit(array_questions) {
    /* ------------------- Event l'input de recherche faq ------------------- */
    $(document).on('change keyup focus', '#faq_search', function () {
        showTabFaq();  // Appel de la fonction pour réinitialiser l'affichage

        var search = $(this).val();   // Valeur du champ de l'input

        /** Si le champ contient au moins 4 caractères **/
        if (search && search.length > 3) {
            $('.search_results li').show().removeClass("hasMatch");

            var array_match = [];

            /* On affiche uniquement celles qui correspondent à la recherche */
            $.each(array_questions, function (i, val) {
                val["nom"] = val["nom"].toUpperCase();
                var match = val["nom"].match(search.toUpperCase());	 // On stocke la question si contient une partie de la saisie ou null
                /* Pour chaque question de la listen si elle matche avec la recherche, on affiche le bloc des résultats et les questions qui ont matchées */
                if (match) {
                    array_match.push(match);
                    $('.search_results').show();
                    $('li#brand_' + val['id']).addClass("hasMatch");
                }
            });

            $('.brand_item:not(.hasMatch)').hide(); // On cache les questions qui n'ont pas matché avec la question

            /* Si pas de résultats */
            if (array_match.length == 0) {
                $('.search_results').hide(); // Cacher le bloc des résultats
            }

            /** Si le champ de recherche est non rempli ou s'il contient moins de 4 caractères, on cache le bloc des résultats**/
        } else {
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
        }
    });

    /* -------------------Event sur le container des résultats ------------------- */
    var click_in_process = false;
    /***  Au maintien du clique sur le container des résultats ***/
    $('.search_results').mousedown(function () {
        click_in_process = true;
    });

    /***  Au relâchement du clique sur le container des résultats ***/
    $('.search_results').mouseup(function () {
        click_in_process = false;
        $('#faq_search').focus(); // Remettre le focus sur l'input
    });

    /*** Lorsqu'on perd le focus de l'input de recherche, cacher le bloc des résultats ***/
    $('#faq_search').blur(function () {
        if (!click_in_process) {
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
        }
    });
}

function showTabFaq(node_id, level, question_id) {
    /*** Appel de la fonction avec un id d'une question séléctionnée  (Clique sur une question des résultats de recherche ou d'une question d'un thème ouvert, pour la gestion d'une question spécifique) ***/
    if (question_id !== null && question_id !== undefined) {
        /** Désactiver et Cacher toutes les questions et les réponses sauf la question et la réponse avec l'id définit */
        $(".faq_question:not(.faq_question_" + question_id + ")").removeClass("active");
        $(".faq_reponse:not(.faq_reponse_" + question_id + ")").slideUp("fast", function () {
                $(this).addClass("cache");
            }
       );
        /** Activer ou désactiver la question avec l'id de la question et afficher ou cacher la réponse avec l'id de la question **/
        $(".faq_question_" + question_id).toggleClass("active");
        $(".faq_reponse_" + question_id).slideToggle("slow", function () {
            $(this).toggleClass("cache");
        });

        /** Scroll Haut de page Positionnement du thème ouvert  **/
        setTimeout(function () {
            $("html, body").animate({scrollTop: $(".faq_element.active").offset().top - 180}, 500);
        }, 250);

        /*** Appel de la fonction sans id d'une question (Clique sur un thème) ***/
    } else {
        /** Désactiver tous les thèmes & Activer le thème séléctionné **/
        $(".faq_element:not(.faq_theme_" + node_id + ")").removeClass("active");
        $(".faq_theme_" + node_id).toggleClass("active");

        /** Cacher toutes le bloc des questions des thèmes désactivés **/
        $(".faq_questions:not(.faq_questions_" + node_id + ")").slideUp("fast", function () {
            $(this).addClass("cache");
        });

        /** Cacher toutes les réponses des thèmes désactivés **/
        $(".faq_questions:not(.faq_questions_" + node_id + ") .faq_reponse").slideUp("fast", function () {
            $(this).addClass("cache");
        });

        /** Afficher ou Cacher les questions du thème sélectionné **/
        $(".faq_questions_" + node_id).slideToggle("fast", function () {
            $(this).toggleClass("cache");
        });

        /** Si thème sélectionnée, scroll vers le thème qui vient d'être ouvert **/
        if (node_id !== undefined) {
            setTimeout(function () {
                $("html, body").animate({scrollTop: $(".faq_element.active").offset().top - 180}, 500);
            }, 250);
        }
    }
}

function researchFaq(node_id, level, question_id) {
    $('.search_results').hide(); // Cacher le bloc des résultats de la recherche

    if (!$(".faq_theme_" + node_id + "").hasClass("active")) { // Si thème pas ouvert, affichage du thème de la question.
        showTabFaq(node_id, level);
    }

    showTabFaq(node_id, level, question_id); // Appel de la fonction pour faire afficher la question séléctionnée
}

// Moves the basket delete button below the basket summary
function updateBasketDeleteCta() {
    if($('#btn_cmd_valid.secondary.btn_validate').length) {
        $('#btn_cmd_valid.secondary.btn_validate').insertBefore($('.elem_tunnel.right_side .bloc_question'));
    }
}

// Toogles message visibility
function openCloseMessage(elem, msg_open, msg_close) {
    var id = elem.attr('id'),
        mId = id.substring(id.indexOf('_') + 1);
    if (!(elem).hasClass('actif')) {
        $('.message_detail').slideUp(800);
        $('.btn_ouvrir_message').not(elem).removeClass('actif');
        $('.message_detail').closest('.wrapper_detail_message').removeClass('actif');
        $('.btn_ouvrir_message').not(elem).children('span').text(msg_open);
        if ($('#msg_' + mId).html() == '') {
            $.ajax({
                type: 'post',
                data: {mId: mId, type: 1},
                url: path_relative_root + create_link('message_detail'),
                success: function (response) {
                    $('#msg_' + mId).html(response);
                    $('#msg_' + mId).slideDown(800);
                }
            });
        } else
            $('#msg_' + mId).slideDown(800);
        (elem).addClass('actif');
        (elem).closest('.wrapper_detail_message').addClass('actif');
        (elem).children('span').text(msg_close);
    } else {

        $('.message_detail').slideUp(800);
        (elem).removeClass('actif');
        (elem).closest('.wrapper_detail_message').removeClass('actif');
        (elem).children('span').text(msg_open);

        setTimeout(function () {
            $("html, body").animate({
                scrollTop: $(".message_detail").offset().top - 400
            }, 500);
        }, 200);

    }
}

// Datalayer v2
function dataLayer_associated_product() {
    if (window.$('#carousel_wrapper')) {
        var product_id = this.getAttribute('data-produit-id') ? event.currentTarget.getAttribute('data-produit-id') : '';
        var productObj = null;
        if (product_id !== '') {
            if (typeof data_layer_products != 'undefined' && data_layer_products.length > 0) {
                data_layer_products.forEach(function (item) {
                    if (item.id == product_id) {
                        productObj = item;
                    }
                })
            } else if (typeof data_layer_products_aimerez != 'undefined') {
                var objs = Object.keys(data_layer_products_aimerez);
                var values = Object.values(data_layer_products_aimerez);

                for (var i = 0; i < objs.length; i++) {
                    if (values[i].id == product_id) {
                        productObj = values[i];
                    }
                }
            }
            var prod = productObj;

            if (productObj !== null && productObj !== undefined) {
                dataLayer.push({
                    'event': 'productClick',
                    'ecommerce': {
                        'click': {
                            'actionField': {'list': productObj['category']},      // Optional list property.
                            'products': [prod]
                        }
                    },
                    'eventCallback': "function () {document.location = productObj.url}"
                });
            }
        }
    }
}

function generateNewBlocProd(page, type, idObj, nb_product, btn, fromScrollBottom, fromScrollTop, fromPagination = false) {

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filters_form'),
        page = (page !== undefined) ? page : form.getValue('page', parseFloat),
        type = type || form.getValue('type'),
        idObj = idObj || form.getValue('idObj'),
        nb_product = nb_product || form.getValue('nb_prod', parseFloat),
        is_from_page = false,
        output = {};

    output = triggerFilter(page, type, idObj, nb_product, 'filters_form', fromPagination);

    nb_product = nb_product || parseFloat($('#nb_prod').val());

    if (nb_product !== undefined) {
        this.nb_prod.value = nb_product;
    } else {
        nb_product = this.nb_prod.value;
    }

    if (page !== undefined && page !== 'all') {
        this.page.value = page * nb_product;
        is_from_page = true;

    }

    if ($('#oneProduct').hasClass('actif')) {
        var one_product = true;
    } else {
        var one_product = false;
    }

    if (isNaN(this.page.value)) {
        this.page.value = 0;
    }

    // Add misc values to output object
    output.page = this.page.value;
    output.type = type;
    output.parentPath = path_relative_root;
    output.idObj = idObj;
    output.nb_prod = nb_product;
    output.is_page = is_from_page;
    output.nb_tot_prod = this.totalElems.value;
    output.object_id = this.object_id.value;

    if ($('input[name="promo"]:checked').length == 0) {
        $('#is_marque').val('');
    }

    if ($('#is_marque').val()) {
        output.is_marque = $('#is_marque').val();
        output.promo = $('#brand_id').val();
    }

    // Si le prix existe
    if ($("input[name=price_min]").length > 0) {
        // Récupération du prix
        output.prix = $("#amount1").val() + "~" + $("#amount2").val();
    }

    if ($("input[name=tri]:checked").val() != undefined) {
        output.tri = $("input[name=tri]:checked").val();
    }

    bloc_prod_xhr = $.ajax({
        url: path_relative_root + create_link('ajax_reload_products'),
        type: 'get',
        data: output,
        success: function (res) {

            var old_elm = document.querySelector('.list_item');
            var content, itm_length;

            if (btn && btn.nextElementSibling && (btn.nextElementSibling.classList.contains('btn_tunnel') || btn.nextElementSibling.classList.contains('loader'))) {
                btn.nextElementSibling.style.display = 'none';
            }

            res = res.split('><><');

            content = res[0].trim();

            itm_length = parseFloat(res[1]);

            // This block of code updates the SEO-related link elements in the HTML document.
            let canonical = res[5];
            let prev = res[6];
            let next = res[7];

            $("link[rel^='canonical']").attr("href", path_root + canonical);
            $("link[rel^='alternate'][hreflang^='fr-fr']").attr("href", path_root + canonical);

            let hrefPrev = $("link[rel^='prev']");
            let hrefNext = $("link[rel^='next']");
            let commentPrev = $('#href_prev');
            let commentNext = $('#href_next');

            changeSeoElement(prev, hrefPrev, commentPrev, 'prev');
            changeSeoElement(next, hrefNext, commentNext, 'next');

            if ($('.rayonCategSlider').length && output.object_id) {

                $('.rayonCategSlider .menuitem').removeClass('actif');
                $(".menuitem[data-idobj='" + idObj + "']").addClass("actif");

                var rayonInitialSlide;

                $('.rayonCategSlider .swiper-slide').each(function (i, obj) {
                    if ($(obj).hasClass('actif')) {
                        rayonInitialSlide = i;
                    }
                });

                rayonCategSlider.slideTo(rayonInitialSlide);
            } else {
                $('.rayonCategSlider .menuitem').removeClass('actif');
            }

            if (content !== '') {
                $('.load_wrapper .pagerNav.end_pagi').show();

                if (!isNaN(page)) {
                    old_elm.setAttribute('data-p', (page * nb_product));
                }

                if (!fromScrollBottom && !fromScrollTop) {
                    old_elm.innerHTML = "";
                }

                document.getElementById('nbr_items').innerHTML = itm_length;
                document.getElementById('totalElems').value = itm_length;

                paginate(page + 1);

                if (fromScrollTop) {
                    var firstMsg = $('#scroll_items .item_container:first');
                    var curOffset = firstMsg.offset().top - $(document).scrollTop();
                    old_elm.insertAdjacentHTML('afterbegin', content);
                    $(document).scrollTop(firstMsg.offset().top - curOffset);
                    //L'inverse pour le bas
                } else {
                    old_elm.insertAdjacentHTML('beforeend', content);
                }

                if (one_product) {
                    $('div.item_container').addClass('full');
                }

                if (parseFloat($('#filters_menu').css('right')) === 0) {
                    toggleMenu('filters', 'right');
                }

                if (typeof lazyloadImage === 'function') {
                    lazyloadImage();
                }
            } else {
                $('.load_wrapper .pagerNav.end_pagi').hide();

                content = '<div class="no_results_wrapper">' +
                    '<div class="search_details_wrapper">' +
                    '<div class="no_result_block">' +
                    Translator.translate('no_result_txt') +
                    '</div>' +
                    '</div>' +
                    '</div>';
                old_elm.innerHTML = content;
            }
        },
        complete: function () {
            let maxItems = document.getElementById('totalElems').value;
            let productLoaded = $('#list_item .item_container:not(".push")').length;

            if (!fromScrollBottom && !fromScrollTop) {
                setTimeout(function () {
                    $("html, body").animate({
                        scrollTop: ($(window).scrollTop() + 10)
                    }, 200);
                }, 250);
            }

            $('.loader_scroll').removeClass('loading');

            if (fromScrollTop || fromScrollBottom) {
                $('#is_loading').val('0');
            }

            setTimeout(function () {
                if ($(btn).closest('.drawer').length && $(btn).closest('.drawer').attr('id')) {
                    var menuId = $(btn).closest('.drawer').attr('id').replace('_menu', '');
                    toggleMenu(menuId);
                }
            }, 200);

            if ($("body.category").length) {
                addClickTrigger(page+1);
                updateLoadedGauge();
            }

            if (productLoaded >= maxItems || productLoaded == 0) {
                $('.pagerNav.end_pagi .loader_scroll').hide(); // Hide "See all" button
            } else {
                $('.pagerNav.end_pagi .loader_scroll').show();
            }
        }
    });

}

// Override App sendMailAlertStock function to allow Products without Color
function sendMailAlertStock(type_alert, form, itm_rr_id) {
    var suffix = form.elements['suff'].value ? '_' + form.elements['suff'].value : '',
        mail_alerte_stock = form.elements['mail_alerte_stock'],
        email = mail_alerte_stock.value,
        produit_id = form.elements['produit_id'].value,
        couleur_id = form.elements['couleurProd'].value;

    var taille_id;
    if (typeof form.elements['itm_size'] !== 'undefined') {
        taille_id = form.elements['itm_size'].value;
    }
    else if (typeof form.elements['idTaille'] !== 'undefined') {
        taille_id = form.elements['idTaille'].value;
        taille_id = form.elements[taille_id].value;
    }

    var mailformat = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i; // REGEX pour les mails

    if (email === '' || !email.match(mailformat)) {
        $('#mail_alerte_stock_' + itm_rr_id).addClass('inputErr');
        $('#bloc_add_alert' + suffix + ' .bloc_add_alert_confirmation').hide();
        $('#bloc_add_alert' + suffix + ' .bloc_add_alert_erreur').show();
    } else if (taille_id !== "none" && (taille_id <= 0)) {
        alert(Translator.translate('js_error_size'));
    } else {
        $('#mail_alerte_stock_' + itm_rr_id).removeClass('inputErr');
        $('#bloc_add_alert' + suffix + ' .bloc_add_alert_erreur').hide();

        $.ajax({
            type: 'post',
            url: path_relative_root + create_link('ajax_mail_alert_stock'),
            data: {
                email: email,
                produit_id: produit_id,
                taille_id: taille_id,
                couleur_id: couleur_id,
                type_alert: type_alert
            },
            success: function (response) {
                $('.alert_stock').hide();
                $('#bloc_add_alert' + suffix + ' .bloc_add_alert_erreur').hide();
                $('#bloc_add_alert' + suffix + ' .bloc_add_alert_form').hide();
                $('#bloc_add_alert' + suffix + ' .bloc_add_alert_confirmation').show();
                // if successful let's send data to GTM
                var dataLayer = window.dataLayer || [];
                dataLayer.push({
                    "event": "stockAlert",
                });
            },
            fail: function () {
                $('#bloc_add_alert' + suffix + ' .bloc_add_alert_confirmation').hide();
                $('#bloc_add_alert' + suffix + ' .bloc_add_alert_erreur').show();
                $('#bloc_add_alert' + suffix + ' .bloc_add_alert_form').show();
            }
        });
    }
}

// add shad when batch is triggered in product list
function openShadBatch(id_elt) {
    $("#shade").addClass("isExpress").addClass("visible");
}

/*** is checked bundle fiche produit  (Function use in load page and in load product) ***/
function checkAddBasket() {
    $('.blocAddBasket').each(function () {
        if ($(this).find($('[name="unit_lot"]:checked')).length) {
            $(this).addClass('isChecked');
            $('.addtobasket').attr('data-productid', $('[name="unit_lot"]:checked').data('productid'));
            $('.addtobasket').attr('data-productbatchnumber', $('[name="unit_lot"]:checked').data('productbatchnumber'));
            $('.addtobasket').attr('data-productprice', $('[name="unit_lot"]:checked').data('productprice'));
        } else {
            $(this).removeClass('isChecked');
        }
    });

    /** Rappel de la fonction au clic sur les inputs des lots.**/
    $('.blocAddBasket').on("click", function () {
        checkAddBasket();
    });
}

// Zooms in item main visual
function zoomImg() {

    var container = $(this).closest('#zoom_container').length ? $(this).closest('#zoom_container') : $('#zoom_container'),
        modbox = $('#zoomBox'),
        shad = $('#shad'),
        colors = $('#autres_couleurs img', container),
        visuals = $('#autres_vues img', container),
        col_tgt = $('.col', modbox),
        vis_tgt = $('.vis', modbox);

    col_tgt.html('');
    vis_tgt.html('');

    if ($('ul#product_pictures_slider li').length > 1) {
        zoomUpdateMain($('.swiper-slide-active img').attr('src').replace('/prodfp/', '/large/'));
    } else {
        zoomUpdateMain($('#img_large', container).attr('src').replace('/prodfp/', '/large/'));
    }

    if (colors.length === 0) {

        col_tgt.parent().hide();
    } else {

        colors.each(zoomAddCol);
    }

    if (visuals.length === 0) {

        vis_tgt.parent().hide();
    } else {

        visuals.each(zoomAddVis);
    }

    shad.unbind('click').one('click', closeModalBox.bind(modbox));

    $(modbox).add(shad).show();
}

/* Params passed to _app loadProduct function in order to correctly init associades swiper in Product Page */
var assoSwiperParams = {
    roundLengths: true,
    setWrapperSize: true,
    lazyLoading: true,
    slidesPerView: 2.1,
    spaceBetween: 10
};

/* Lightbox Alert Stock override from _app */
function lightboxAlertStock(productId, type_alert) {

    var form = this.form;

    var colorId = tailleId = 0;
    if (form.elements['couleurId']) {
        colorId = form.elements['couleurId'].value;
    } else if (form.elements['prod_couleur']) {
        colorId = form.elements['prod_couleur'].value;
    } else if (form.elements['couleurProd']) {
        colorId = form.elements['couleurProd'].value;
    }

    if (form.elements['tailleId']) {
        tailleId = form.elements['tailleId'].value;
    } else if (form.elements['prod_taille']) {
        tailleId = form.elements['prod_taille'].value;
    } else if (form.elements['tailleProd']) {
        tailleId = form.elements['tailleProd'].value;
    }

    // Les info supplémentaire
    data = {
        'produit_id': productId,
        'taille_id': tailleId,
        'couleur_id': colorId,
        'from': 'nothing',
        'type_alert': type_alert
    };

    $.ajax({
        type: 'post',
        url: path_relative_root + 'ajax_lightbox_alert.php',
        data: data,
        success: function (response) {
            try {
                response = $.parseJSON(response);
            } catch (e) {
                console.error('parseJSON');
                return;
            }

            if (response.success == 'ok') {
                var datas = response.datas;
                var $html = $(datas.html);
                $('#lightbox_stock_alert').html($html);
            } else {
                $('#btn_error').fadeIn();
            }
        },
        complete: function () {
            $('#bloc_btn_loader, .loader').fadeOut(300);
            if (typeof (produit_page_id) == 'undefined') {
                document.getElementById('lightbox_stock_alert').classList.add('open');
                modBoxOpen.call(document.getElementById('displaySizeAlert'));
            } else {
                modBoxOpen.call(document.getElementById('btn_add_alert_' + productId));
            }
        }
    });
}

/* Custom Alert function */
alert = (function () {
    "use strict";

    var shade, albox, alert_tmpl;
    var regex = new RegExp("\\s", "g");

    function init() {

        shade = document.getElementById("shade");
        albox = document.getElementById("alert_box");

        if (shade !== null && albox !== null) {
            alert_tmpl = albox.innerHTML.trim();

            window.removeEventListener("load", init);
        }
    }

    function closeAlertBox() {

        modBoxClose.call(document.getElementById('alert_box'));
    }

    function alert(str) {

        $(".alert_box_content", albox).html(str);

        $(".close", albox).on("click", closeAlertBox);
        $(shade).one("click", closeAlertBox);

        modBoxOpen.call(document.getElementById('alert_box'));
    }

    window.addEventListener("load", init);

    return alert;
})();

/**
 * Migrate search bar when scroll down
 */
function onWindowScroll() {

    var pageBody = document.body;
    var oldScrollY = window.scrollY;

    window.onscroll = function (e) {

        if (oldScrollY < window.scrollY && oldScrollY > 100) {

            pageBody.classList.add('compactSearch');
        } else {

            pageBody.classList.remove('compactSearch');
        }

        oldScrollY = window.scrollY;
    }
}

function modBoxCloseExtra() {
    // Fix "item_added" not displaying when closed before
    setTimeout(function () {
        if ($('#item_added').css('display') === 'none') {
            $('#item_added').css('display', '');
        }
    }, 500);
}

/**
 * Override App loadProduct for custom prod_taille & prod_couleur
 */
function loadProduct(type_filter, id, type_page, itm_rr_id) {

    var is_wishlist = false;
    var is_achat_express_v2 = (this.form !== undefined && this.form.elements['achatExpressV2'] !== undefined && !is_wishlist);
    var taille_id = $('.prodSize:checked').val();

    if (typeof (id) == 'undefined') {
        id_prod = "";
    } else {
        id_prod = "_" + id;
    }

    if (typeof (type_page) == 'undefined') {
        type_page = 'product';
    }

    var $form = '';
    var data = '';

    if (!is_achat_express_v2) {
        $form = $('#item_add_form');

        if (typeof (id) == 'undefined') {
            data += $form.serialize();
        }

        var new_product_id = 0;
        var couleur_id = 0;
        var is_diffuseur = ($('input[name=is_diffuseur]', $form).val());
        if (is_diffuseur == 1) {
            couleur_id = $(".prodColor:checked", $form).val();
            new_product_id = $(".prodColor:checked", $form).attr("data-productid");
        } else {
            couleur_id = $("#prod_couleur").val();
            new_product_id = $("#prod_couleur option:selected").attr("data-produitid");
        }

        data += '&couleurProd=' + couleur_id;
        data += '&tailleProd=' + taille_id;
        data += '&type=' + type_filter;
        data += '&type_page=' + type_page;
        if (document.getElementById('wishlist')) {
            data += '&type_page=' + type_page;
        }

        if (typeof (id) != 'undefined') {
            var produit_principal = $('#produit_principal' + id_prod).val();
            data += '&produit_principal=' + $('#produit_principal' + id_prod).val();
        }

        data += '&produit_id_new=' + new_product_id;

        var div_produit_achat_express_v2 = $(this).parents('.swiper-slide');

        exitProductFieldset("color_list_wrapper");

    } else {

        var id_prod = "";
        var old_id = this.form.elements['produit_id'].value;
        var isCategSuper = this.form.elements['isCategSuper'].value;
        var new_id = id;
        var cpt_prod = itm_rr_id !== '' ? itm_rr_id.replace('_', '') : '';

        var couleur_id = 0;
        couleur_id = $(this).val();

        data += 'produit_id=' + old_id;
        data += '&produit_principal=' + old_id;
        data += '&couleurProd=' + couleur_id;
        data += '&tailleProd=';
        data += '&qteProd=1';
        data += '&hdn_product_id=' + old_id;
        data += '&produit_id_new=' + new_id;
        data += '&type=' + type_filter;
        data += '&type_page=' + type_page;
        data += '&is_achat_express=0';
        data += '&is_achat_express_v2=1';
        data += '&cpt_prod=' + (cpt_prod - 1);
        data += '&isCategSuper=' + isCategSuper;
    }

    $.ajax({
        type: 'post',
        url: path_relative_root + create_link('ajax_get_product_regroup_ref'),
        data: data,
        beforeSend: function () {
            $('#bloc_btn_loader').show();
            $('#btn_error').fadeOut(300);
        },
        success: function (response) {

            try {
                response = $.parseJSON(response);
            } catch (e) {
                console.error('parseJSON');
                return;
            }

            if (response.success == 'ok') {

                if (window.dataLayer !== undefined) {
                    window.dataLayer.push({
                        'event': 'view_item',
                    });
                }

                var datas = response.datas;

                if (type_page == 'product') {

                    var $html_main = $(datas.html_main);

                    if (is_achat_express_v2) {
                        // Photo and information
                        div_produit_achat_express_v2.replaceWith($html_main);
                        if ($('#oneProduct') != undefined && $('#oneProduct').hasClass('actif')) {
                            $('.itm_achat_express_' + new_id).addClass('full');
                        }
                        if ($('.container_products_lookbook').length > 0) {
                            //Avoid breaking the style for kujten
                            if ($('#is_from_kujten') == undefined) {
                                $html_main.removeClass('item_container');
                                $html_main.addClass('item');
                            }
                        }

                        if (swiper_achat_express_v2) {
                            if (typeof (four_images) != 'undefined') {
                                enableSwiperFourImage();
                            } else {
                                var id_swiper = swiper_achat_express_v2.attr('id');
                                refreshSliderAchatExpress(id_swiper);
                            }
                        }

                    } else {

                        var $fiche = $('#contents_wrapper');
                        var replace_main = new Array(
                            '#wrapper_product_name',
                            '#zoom_container',
                            '.prod_page_bot',
                            '.compo',
                            '.associations_section',
                            '.accordion_container',
                            '.product_infos .price_container',
                            '.cms-page-module',
                            'w-netreviews-stars',
                            'w-netreviews-comments',
                            '.description_courte',
                            '.caracNav',
                            '.resume',
                            '.netreviews_reviews',
                            '#item_add_form'
                        );

                        var product_refext_prod = $('input[name=itm_refext_prod]', $html_main).val();

                        for (var i = 0; i < replace_main.length; i++) {
                            var id = replace_main[i];

                            if (id == '.accordion_container') {

                                // Recover each accordion_item id so we can replace each one individually
                                $('.accordion_item', $fiche).each(function () {
                                    var acc_item_id = $(this).attr('id');

                                    if ($("#" + acc_item_id, $html_main).length) {
                                        // Is item was previously hidden, remove cache class
                                        if ($(this).hasClass('cache')) {
                                            $(this).removeClass('cache')
                                        }

                                        // Update item content in fiche with ajax response content
                                        $(this).html($("#" + acc_item_id, $html_main).html());
                                    } else {
                                        // If fiche accordion item isn't present in ajax response, hide it by adding cache class
                                        $(this).addClass('cache');
                                    }
                                });

                            } else if (id == '.description_courte') {
                                // // Block not directly accessible on html_main (method just above), forced to bypass with this method - EX: Messegue
                                $html_main.each(function () {
                                    if ($(this).hasClass('description_courte')) {
                                        $('.description_courte', $fiche).html($(this).html());
                                    }
                                });
                            } else if (id == '.caracNav') {

                                // Titles
                                $('.caracLabel', $html_main).each(function () {
                                    var item_id = $(this).attr('id');
                                    $("#" + item_id, $fiche).html($(this).html());
                                });

                                // Contents matching titles
                                $('.caracWrapper', $html_main).each(function () {
                                    var item_id = $(this).attr('id');
                                    $("#" + item_id, $fiche).html($(this).html());
                                });

                            } else if (id == 'w-netreviews-stars') { // New Avis verifies
                                $("#w-netreviews-stars", $fiche).html($('w-netreviews-stars', $html_main).html());
                                // Reload vuejs avis verifies
                                if (typeof wShop !== 'undefined' && wShop.$refs.wNetreviewsStars) {
                                    // Avis verifies stars
                                    if (wShop.$refs.wNetreviewsStars != 'undefined') {
                                        wShop.$refs.wNetreviewsStars.reload(product_refext_prod);
                                    }
                                }
                            } else if (id == 'w-netreviews-comments') { // New Avis verifies
                                $("#w-netreviews-comments", $fiche).replaceWith($('w-netreviews-comments', $html_main).wrap('<p/>').parent().html());
                                // Reload vuejs avis verifies
                                if (typeof wShop !== 'undefined' && wShop.$refs.wNetreviewsComments) {
                                    // Avis verifies reviews
                                    if (wShop.$refs.wNetreviewsComments != 'undefined') {
                                        wShop.$refs.wNetreviewsComments.reload(product_refext_prod);
                                    }
                                }
                            } else {
                                // In case info is not present in response, remove it (ex: cms-page-module on one ref but not on an other)
                                if ($(id, $fiche).length && $(id, $html_main).length) {
                                    $(id, $fiche).html($(id, $html_main).html());
                                } else {
                                    if ($(id, $fiche).length && !$(id, $html_main).length) {
                                        $(id, $fiche).replaceWith('');
                                    }
                                }

                                /******* OLD AVIS VERIFIES ************/
                                if (id == '.netreviews_reviews') {
                                    if ($('#avis_verifie_produit').length || $('.netreviews_reviews').length || $('.netreviews_stars').length) {
                                        if (typeof netreviews_product_url !== "undefined") {
                                            // netreviews_product_url defined in views_mobile_new/main.php
                                            (function () {
                                                var script = document.createElement('script');
                                                script.setAttribute('id', 'ajax_verified');
                                                script.setAttribute("type", "text/javascript");
                                                script.setAttribute("src", netreviews_product_url);

                                                // If ajax already being done, verify if script exists before adding it to HEAD
                                                if (document.getElementById('ajax_verified') !== null) {
                                                    document.querySelector('#ajax_verified').remove();
                                                }

                                                document.getElementsByTagName("head")[0].appendChild(script);
                                            })();
                                        }
                                    }

                                    if (typeof nrSearchForStars !== "undefined" && $('.NETREVIEWS_PRODUCT_STARS').length) {
                                        nrSearchForStars();
                                    }

                                    // Display avis verifie comments
                                    // Call nrSearchForReviews from tag.min.js avis verifie NETREVIEWS_PRODUCT_URL
                                    if (typeof nrSearchForReviews !== "undefined" && $('.NETREVIEWS_PRODUCT_REVIEWS').length) {
                                        nrSearchForReviews();
                                        setTimeout(function () {
                                            if ($('#netreviews_reviews_tab').height() === undefined) {
                                                $("#avis_verifies").hide();
                                            } else {
                                                $("#avis_verifies").show();
                                            }
                                        }, 1500);
                                    }
                                }
                            }
                        }

                        /******* ASSOCIATED PRODUCTS ************************************/
                        /***************************************************************/

                        var slides_per_view = typeof associatedProduct_spv != "undefined" ? associatedProduct_spv : 3;
                        var asso_btn_prev = typeof assoBtnPrev != "undefined" ? assoBtnPrev : ".swiper-button-prev";
                        var asso_btn_next = typeof assoBtnNext != "undefined" ? assoBtnNext : ".swiper-button-next";
                        var asso_space_between = typeof assoSpaceBetween != "undefined" ? assoSpaceBetween : 20;

                        var asso_swiper_params = typeof assoSwiperParams != "undefined" ? assoSwiperParams : {
                            slidesPerView: slides_per_view,
                            spaceBetween: asso_space_between,
                            navigation: {
                                nextEl: asso_btn_next,
                                prevEl: asso_btn_prev
                            }
                        };

                        if ($('.associations_section', $html_main).length > 0) {

                            // Check if associated products are displayed as tabs
                            if ($('.product_associations_wrapper', $html_main).attr('data-tab') !== undefined) {
                                // First check if there's an active class on each titles and wrappers
                                if (!$('.association_title.active').length) {
                                    $('.association_title').first().addClass('active');
                                }

                                if (!$('.product_associations_wrapper.active').length) {
                                    $('.product_associations_wrapper').first().addClass('active');

                                    // Init also swiper on page load
                                    if ($('.product_associations_wrapper.active .swiper-container .swiper-slide').length > Math.floor(asso_swiper_params.slidesPerView)) {
                                        new Swiper('.product_associations_wrapper.active .swiper-container', asso_swiper_params)
                                    }
                                }

                                // Once first title and first swiper is active, handle click on each tabs dynamically
                                $('.association_title').on('click', function () {

                                    var title_tab = $(this).attr('data-tab');
                                    var active_tab = $('.product_associations_wrapper.active');

                                    // If the clicked title is not active yet, set it to active and remove active class from the last title
                                    if (!$(this).hasClass('active')) {
                                        $('.association_title.active').removeClass('active');
                                        $(this).addClass('active');
                                    }

                                    // If new active title, display matching associations tab
                                    if (title_tab != active_tab.attr('data-tab')) {
                                        active_tab.removeClass('active');
                                        $('.product_associations_wrapper[data-tab="' + title_tab + '"]').addClass('active');

                                        // Swiper activation
                                        if ($(".swiper-container", '.product_associations_wrapper[data-tab="' + title_tab + '"]')) {
                                            // If more than slides_per_view, we activate the swiper
                                            if ($('.product_associations_wrapper[data-tab="' + title_tab + '"] .swiper-slide').length > Math.floor(asso_swiper_params.slidesPerView)) {
                                                setTimeout(new Swiper('.product_associations_wrapper[data-tab="' + title_tab + '"] .swiper-container', asso_swiper_params), 500);
                                            }
                                        }
                                    }
                                });

                            }
                        } else {

                            // Init product_associations_wrapper swiper(s)
                            if ($('.product_associations_wrapper').length > 1) {

                                $('.product_associations_wrapper').each(function () {
                                    if ($(this).find('.swiper-slide').length >= Math.floor(asso_swiper_params.slidesPerView)) {
                                        new Swiper(($(this).find('.swiper-container')), asso_swiper_params)
                                    }
                                });
                            } else if ($('.product_associations_wrapper').length > 0) {
                                if ($('.product_associations_wrapper').find('.swiper-slide').length >= Math.floor(asso_swiper_params.slidesPerView)) {
                                    new Swiper('.product_associations_wrapper .swiper-container', asso_swiper_params);
                                }
                            }
                        }

                        // If there's not an associations_section working with a tabulation mechanism -> check carousel_wrapper
                        if (!$('.associations_section', $html_main).length) {
                            if ($('#carousel_wrapper', $html_main).length > 0) {
                                // For sites using the _app view, some sites don't want one particular swipers on reload so remove it from dom
                                if (typeof carouselException != 'undefined') {
                                    var new_carousel = $('#carousel_wrapper', $html_main);

                                    // Removing carousel exception from html_main response
                                    if ($('.ranges_carousel_wrapper.' + carouselException, new_carousel).length) {
                                        $('.ranges_carousel_wrapper.' + carouselException, new_carousel).remove();
                                    }

                                    $('#carousel_wrapper').html(new_carousel.html());
                                } else {
                                    $('#carousel_wrapper').html($('#carousel_wrapper', $html_main).html());
                                }

                                // Check if site has declared several associated swipers for its FP
                                if (typeof oAssociatedSwipers != 'undefined' && typeof oAssociatedSwipers == 'object') {

                                    // Loop and init swipers
                                    $.each(oAssociatedSwipers, function (container, params) {
                                        // Check if there are enough slides to init swiper
                                        if ($(container).length > 0) {
                                            if ($(container).find('.swiper-slide').length >= params.slidesPerView) {
                                                // Check if swiper-container is a sibling or a child
                                                window.setTimeout(
                                                    new Swiper($(container)[0], params)
                                                    , 500);
                                            }
                                        }
                                    })
                                } else {
                                    // If swipers are identical, init them the classic way
                                    if ($('#carousel_wrapper .swiper-container').length > 1) {
                                        $('#carousel_wrapper .swiper-container').each(function () {
                                            if ($(this).find('.swiper-slide').length >= asso_swiper_params.slidesPerView) {
                                                window.setTimeout(
                                                    new Swiper($(this)[0], asso_swiper_params)
                                                    , 500);
                                            }
                                        });
                                    } else if ($('#carousel_wrapper .swiper-container').length > 0) {
                                        if ($('#carousel_wrapper .swiper-container').find('.swiper-slide').length >= asso_swiper_params.slidesPerView) {
                                            window.setTimeout(
                                                new Swiper('#carousel_wrapper .swiper-container', asso_swiper_params)
                                                , 500);
                                        }
                                    }

                                }
                            } else {
                                $('#carousel_wrapper').html('');
                            }
                        }
                        if ($('#publication_page', $html_main).length > 0) {
                            $('#publication_page').html($('#publication_page', $html_main).html());
                        } else {
                            $('#publication_page').html('');
                        }

                        window.prod_slider = document.getElementById("product_pictures_slider");
                        window.asso_slider = document.getElementById('asso_product_carousel');
                        window.gamm_slider = document.getElementById('gamme_product_carousel');

                        /** Swiper function for gamme && assoc products
                         * TODO => Call it for assoc also
                         */
                        var swiperGamme = function (element, isCenteredSlides, isLoop) {
                            new Swiper(element, {
                                slidesPerView: 3,
                                centeredSlides: isCenteredSlides,
                                paginationClickable: true,
                                spaceBetween: 20,
                                pagination: '.swiper-pagination',
                                preloadImages: true,
                                lazyLoading: true,
                                loop: isLoop,
                                navigation: {
                                    nextEl: '.swiper-button-next',
                                    prevEl: '.swiper-button-prev',
                                },
                            });
                        }

                        if (asso_slider && $('.swiper-slide', asso_slider).length > 0 && (typeof assoSwiperParams == "undefined")) {
                            var assoSwiperSlider = new Swiper('.swiper-container.asso_product_carousel', {
                                slidesPerView: 3,
                                centeredSlides: true,
                                paginationClickable: true,
                                spaceBetween: 20,
                                pagination: '.swiper-pagination',
                                preloadImages: true,
                                lazyLoading: true,
                                loop: true,
                                navigation: {
                                    nextEl: '.swiper-button-next',
                                    prevEl: '.swiper-button-prev',
                                },
                            });
                        }

                        if (gamm_slider && $('.swiper-slide', gamm_slider).length > 0 && (typeof assoSwiperParams == "undefined")) {

                            if ($(window.gamm_slider).hasClass('noLoop')) {

                                /** Gamme swiper without loop => prevent products repetition bug */
                                gammSwiperSlider = swiperGamme('.swiper-container.gamme_product_carousel', false, false);
                            } else {

                                /** Default gamme swiper */
                                gammSwiperSlider = swiperGamme('.swiper-container.gamme_product_carousel', true, true);
                            }

                        }

                        /* Special case for a single view slider */
                        if ($('.assocSlider').length > 0 && $('.assocSlider .swiper-slide').length >= 1 && (typeof assoSwiperParams == 'undefined')) {
                            var assoSlider = new Swiper('.assoSlider', {
                                slidesPerView: 1,
                                spaceBetween: 0,
                                preloadImages: true,
                                lazyLoading: true,
                                loop: true,
                                navigation: {
                                    nextEl: '.swiper-button-next',
                                    prevEl: '.swiper-button-prev',
                                },
                            });
                        }

                        if ($('.gammeSlider').length > 0 && $('.gammeSlider .swiper-slide').length >= 1 && (typeof assoSwiperParams == "undefined")) {
                            var gammSlider = new Swiper('.gammeSlider', {
                                slidesPerView: 1,
                                spaceBetween: 0,
                                preloadImages: true,
                                lazyLoading: true,
                                loop: true,
                                navigation: {
                                    nextEl: '.swiper-button-next',
                                    prevEl: '.swiper-button-prev',
                                },
                            });
                        }

                        //set slider product
                        if (prod_slider && $(".swiper-slide", prod_slider).length > 1) {
                            var slidesPerView = $('#zoom_container').data('slidesperview');
                            if (slidesPerView == undefined) {
                                slidesPerView = 1;
                            }
                            var spaceBetween = $('#zoom_container').data('spacebetween');
                            if (spaceBetween == undefined) {
                                spaceBetween = 0;
                            }
                            var prodSwiperSlider = new Swiper('#zoom_container .swiper-container', {
                                slidesPerView: slidesPerView,
                                mode: 'horizontal',
                                centeredSlides: true,
                                pagination: '.swiper-pagination',
                                paginationClickable: true,
                                preloadImages: true,
                                lazyLoading: true,
                                spaceBetween: spaceBetween,
                                loop: false,
                                navigation: {
                                    nextEl: '.swiper-button-next',
                                    prevEl: '.swiper-button-prev',
                                },
                            });
                        }

                        var string_params = '';
                        if ($("#produit_lot_nb").val() != undefined) {
                            string_params += '&produit_lot_nb=' + $("#produit_lot_nb").val();
                        }

                        // depending on type_filter, update either color checked or size selected
                        if (type_filter == 'taille') {
                            $('#prod_taille [value=' + taille_id + ']').prop('selected', true);
                        }

                        if (type_filter == 'couleur') {
                            $('#prod_couleur #color_' + couleur_id).prop('checked', true);
                        }

                        onAttrSelect(parseFloat(datas.product.id));

                        var contentMovable = $('#content_movable');

                        $('.jqzoom').on('doubletap', function () {
                            zoomImg.call(this);
                            $('#zoomBox').addClass('active');

                            contentMovable.addClass('visible');
                            var zoomboxWidth = $('body').width(),
                                scrollTo = zoomboxWidth / 2;
                            setTimeout(function () {
                                $('#zoomBox').scrollLeft(scrollTo);
                            }, 'slow');
                        });

                        contentMovable.on('doubletap', function () {
                            $(this).removeClass('visible');
                            $('#zoomBox .btn_close').trigger('click');
                        });

                        //check stock product
                        var hasStock = false;
                        var stocks = response.stock;
                        $.each(stocks, function (index, stock) {
                            $.each(stock, function (key, val) {
                                if (val === true) {
                                    hasStock = true;
                                }
                            });
                        });

                        //if we have stock, we display the corresponding elements
                        if (hasStock) {
                            if ($("#bloc_add_basket") !== undefined) {
                                $("#bloc_add_basket").show();
                            }

                            if ($("#link_book_in_store") !== undefined) {
                                $("#link_book_in_store").show();
                            }
                            if ($("#bloc_add_alert") !== undefined) {
                                $("#bloc_add_alert").hide();
                            }
                        } else {
                            //otherwise, hide them
                            if ($("#bloc_add_basket") !== undefined) {
                                $("#bloc_add_basket").hide();
                            }
                            if ($("#link_book_in_store") !== undefined) {
                                $("#link_book_in_store").hide();
                            }
                            if ($("#bloc_add_alert") !== undefined) {
                                $("#bloc_add_alert").show();
                            }
                        }
                    }
                } else if (type_page == 'look') {

                    var $html_main = $(datas.main_content);

                    $('#ref_look_' + produit_principal).html($('#ref_look_' + produit_principal, $html_main).html());
                    $('#look_add_lightbox_' + produit_principal).html($('#look_add_lightbox_' + produit_principal, $html_main).html());

                    calculTotal('slide_look_detail');

                    var $id_current = id_prod.substring(id_prod.lastIndexOf("_") + 1);
                    var i = array_look.indexOf(parseInt($id_current));

                    if (i != -1) {
                        array_look.splice(i, 1);
                    }

                    // Once the information is loaded, we will update the quantity select with the newly chosen product
                    showQteListeGamme('liste_qte_' + datas.product.id, datas.product.id, path_relative_root);

                } else if (type_page == 'assoc') {

                    var $html_main = $(datas.html_main);
                    $('#ref_assoc_' + produit_principal).html($('#ref_assoc_' + produit_principal, $html_main).html());

                    // Once the information is loaded, we will update the quantity select with the newly chosen product
                    showQteListeGamme('liste_qte_' + datas.product.id, datas.product.id, path_relative_root);
                }

                if ($('#btn_add_alert', response.datas.html_main) && $('#btn_add_alert', response.datas.html_main).attr('onclick').indexOf('lightboxAlertStock') !== false) {
                    $(".eclat_product_nouveaute_fiche_produit").show();
                }

                if (document.getElementById("alma-widget") !== null) {
                    initAlmaWidget();
                }

            } else {
                $('#btn_error').fadeIn();
            }
        },
        complete: function () {

            $('#bloc_btn_loader').fadeOut(300);

            if (window.lazyload) {
                lazyload.init();
            }

            setTimeout(function () {
                $(".zoom_indicator").fadeOut(800)
            }, 2500);
        }

    });
}

// Override because specific case on messegue because we add the product directly to the basket if it has only one size
function openSizeAchatExpressV2() {

    var form = this.form;
    var $div_color = $(form).find('.productColorFieldset');
    var $div_size = $(form).find('.productSizeFieldset');
    var size_id = $(form).find('input[name="tailleProd"]').val();

    if (size_id > 0 && $div_size.find('input[name="itm_size"]').length) {
        sendFormUpdateCart.call(form);
    } else {
        var $div_alert_stock = $(form).find('.bloc_add_alert');
        $div_alert_stock.hide();

        var lightbox = $(this.form).find('.achat_express');
        var lightBoxColors = lightbox.hasClass('displayColors');

        if (lightBoxColors) {
            lightbox.removeClass('displayColors');
        }

        if (size_id !== 'none') {
            form.elements['itm_size'].checked = false;
        }

        if (!$('body').hasClass('homepage')) {
            $("#shade").addClass("isExpress").addClass("visible");
        }

        if ($div_size.hasClass('actif')) {
            $(form).find('.achat_express').hide();
            $div_size.removeClass('actif');
        } else {
            $div_color.removeClass('actif');
            if (size_id === 'none') {
                sendFormUpdateCart.call(form);
            } else {
                $(form).find('.achat_express').show();
                $div_size.addClass('actif');
            }
        }

        if (
            (typeof GEM_Services != 'undefined' && typeof GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE != 'undefined' && GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE !== false) ||
            (typeof GEP != 'undefined' && typeof GEP.isOperatedByGlobale != 'undefined' && GEP.isOperatedByGlobale !== false)
       ) {
            $('.no_stock_entrepot').each(function () {
                if ($(this).html() == '') {
                    if (!$(this).prev().hasClass('no_stock')) {
                        $(this).prev().addClass('no_stock');
                        $(this).prev().show();
                    }
                } else {
                    $(this).show();
                }
            });
        }
    }
}

// Afficher le formulaire d'inscription alerte stock
function showAlertStock(el) {
    if (typeof openShadBatch !== "undefined") {
        openShadBatch($(el).parent().attr("id"));
    }

    $(el).find('.rollover').show();
    $(el).find('.displayInfoStock').show();
    $(el).find('.btnAddBasketWrapper.bloc_add_alert').show();
    $('#shade').addClass('express_visible');
}

function changeFiltre(form, reset) {
    var value = getInputVal.bind(form);
    var type = value('type_tri'),
        idObj;
    var btn = this,
        idObjTmp;

    // If form must be reset
    if (reset) {
        if (filterformColors && filterformSize) {
            document.getElementById('wrapper_couleur_filtre').innerHTML = filterformColors;
            document.getElementById('wrapper_taille_filtre').innerHTML = filterformSize;
        }

        resetExtraFilters();
        form.reset();
        $('#filters_menu input[checked]').not('#wrapper_sscat_filtre input[checked]').removeAttr("checked"); //force suppression de tous les filtres checked (sauf ss-cat), ce qui arrive lorsqu'on revient d'une FP.
    }
    $('legend.open', form).each(function () {

        toggleFiltersMenu.call(this);
    });

    // Check obj type
    if (form.sscat) {

        // Attention ce n'est pas des sscat mais des bien objets (views_mobile_new/wrapper_filters.php)
        idObjTmp = value('sscat');

        if (idObjTmp && idObjTmp.length > 0) {

            type = 'obj';
            $('#object_id').val(idObjTmp);
        } else {

            type = 'subcat';
        }
    }

    $('#type_tri').val(type);

    idObj = getCurrentIdRayon();

    // Display loader
    if (btn.nextElementSibling && btn.nextElementSibling.classList.contains('button')) {
        btn.nextElementSibling.style.display = 'block';
    }

    // Get new elements
    if (from_type === 'product') {
        generateNewBlocProd.call(form, 0, type, idObj, value('nb_prod'), btn);
    } else {
        generateNewBlocSearch.call(form, value('nb_prod'), 0, btn);
    }

    return false;
}

function updateColor() {}

// Pretty straightforward: this formats a price
var formatPrice = (function () {

    var THREEDIGITS = new RegExp("\\d{3}", "g");
    var FORMAT = window.PRICE_FORMAT_PLAIN_JS;

    // Splits a number into chunks of 3 digits
    function splitThousands(nmbr) {

        var f_chunk = '', chunk_length;

        nmbr = String(nmbr);

        chunk_length = nmbr.length % 3;

        if (chunk_length) {

            f_chunk = nmbr.slice(0, chunk_length);
            nmbr = nmbr.substr(chunk_length);
        }

        return f_chunk + nmbr.replace(THREEDIGITS, function (match) {
            var CURRENCY = window.siteCurrency;
            var thousandSep = CURRENCY.thousands_sep ?? ' ';
            return thousandSep + match;
        });
    }

    return function (price) {
        var CURRENCY = window.siteCurrency;
        var FORMAT = window.PRICE_FORMAT_PLAIN_JS;

        var output;

        if (price.constructor !== String) {

            price = price.toString(10);
        }

        price = price.replace('.', ',');
        price = price.split(',');

        if (price[1] === undefined) {
            price[1] = '00';
        } else if (price[1].length == 1) {
            price[1] = price[1] + '0';
        }

        if (typeof window.PRICE_FORMAT_PLAIN_JS_NO_CENTS != 'undefined' && window.PRICE_FORMAT_PLAIN_JS_NO_CENTS != 'PRICE_FORMAT_PLAIN_JS_NO_CENTS') {
            var FORMAT_NO_CENTS = window.PRICE_FORMAT_PLAIN_JS_NO_CENTS;
            if (price[1]=='00' || price[1]=='') {
                FORMAT = PRICE_FORMAT_PLAIN_JS_NO_CENTS;
            }
        }

        output = FORMAT.replace('[<unit>]', splitThousands(price[0]));
        output = output.replace('[<symbol_right>]', CURRENCY.symbol_right);
        output = output.replace('[<symbol_left>]', CURRENCY.symbol_left);
        output = output.replace('[<decimal>]', price[1]);
        output = output.replace('[<code>]', CURRENCY.code);

        return output;
    };
}());

function changeQte(type) {
    var types = ["plus", "less"],
        item,
        loader,
        qtty,
        ajax_url;

    if (types.includes(type)) {
        item = this.getValue("item_id", parseFloat);
        loader = document.getElementById("qte_loader_" + item);
        qtty = this.getValue("selected_qte", parseFloat);

        this.qtty_select_wrap.style.display = "none";
        loader.style.display = "initial";

        // Save the state of all elements with the 'cart_item_actions.clearfix' class and if they have 'actif'
        var cartItemActions = document.querySelectorAll('.cart_item_actions.clearfix');
        var actifState = Array.from(cartItemActions).map(el => el.classList.contains('actif'));

        switch (type) {
            case "plus":
                qtty += 1;
                break;
            case "less":
                qtty -= 1;
                break;
        }

        $.ajax({
            url: path_relative_root + create_link("order_basket"),
            type: "post",
            data: {
                action: "del",
                panierId: item,
                cad: "non",
                pointcad: 0,
                qteProd: qtty,
                change_qte: true,
            },
            cache: false,
            success: function (res) {
                try {
                    res = JSON.parse(res);
                } catch (e) {
                    console.error("JSON ERROR: could not parse data");
                    return false;
                }

                document.getElementById("contents_wrapper").innerHTML = res.datas;
                if (wShop.$refs.wCouponForm) {
                    wShop.$refs.wCouponForm.reloadBloc += 1;
                }

                // Update top cart
                var response = ajax_file("ajax_show_basket.php");
                $("#cart_btn").data("cart", response);

                // Move out of stock products to the top of the cart
                updateCartElements();
                updateBasketDeleteCta();

                if (typeof initApplePayExpressCheckout === "function") {
                    initApplePayExpressCheckout();
                }

                wishlistAddText();
                wishlistEditBloc();

                // Reapply the 'actif' class to the updated elements
                var updatedCartItemActions = document.querySelectorAll('.cart_item_actions.clearfix');
                updatedCartItemActions.forEach((el, index) => {
                    if (actifState[index]) {
                        el.classList.add('actif');
                    }
                });
            },
        });
    }
}

/**
 * Initializes the product fieldset by simulating the behavior of a select element.
 *
 * @param {string} id - The ID of the element to initialize.
 *
 * This function adds the "opened" class to the element with the given ID and the "actif" class to the element with the ID "shad".
 * It also sets up an event listener on the "shad" element to remove these classes when it is clicked or touched.
 */
function initProductFieldset(id) {
    // Function to simulate the behavior of a select
    var listColorSize = $("#" + id + ".choices_list");
    var shadSelect = $("#shade");

    listColorSize.addClass("opened");
    shadSelect.addClass("actif");

    shadSelect.on("click touch", function () {
        listColorSize.removeClass("opened");
        $(this).removeClass("actif");
    });
}

function exitProductFieldset(id) {
    var shadSelect = $("#shade");
    var listColorSize;
    if (id !== undefined) {
        /*  Function to simulate the behavior of a select */
        listColorSize= $("#" + id +".choices_list");
    } else{
        listColorSize= $(".choices_list.opened");
    }
    listColorSize.removeClass("opened");
    shadSelect.removeClass("actif");
}

function loadMarque(actif_principal, actif_secondaires) {
    $.ajax({
        type: 'post',
        url: path_relative_root + create_link('ajax_get_marquebyid'),
        data: {'actif_principal': actif_principal, 'actifs_secondaires': actif_secondaires},
        dataType: 'json',
        success: function (response) {

            if (response.success) {

                for (var i = 0; i < response.result.length; i++) {

                    if (i == 0) {
                        $('#actif_principal #actif_secondaires_val_h4').html(response.result[i].marque_desc);
                        $('#actif_principal #actif_principal_val_h3').html(response.result[i].marque_titre);

                        var nom_marque = response.result[i].marque_nom.toLowerCase();
                        $('#actif_principal .links a').first().attr('href', path_relative_root + '#search=' + nom_marque + '&b=' + response.result[i].marque_id);
                        $('#actif_principal .links a').last().attr('href', path_relative_root + 'nos-plantes-et-actifs/' + response.result[i].marque_path + '.html');
                        $('#bloc_actif_principal img').attr('src', path_relative_root + 'img/marque/' + response.result[i].marque_id + '.jpg');
                        $('#actif_principal').attr('href', path_relative_root + 'nos-plantes-et-actifs/' + response.result[i].marque_path + '.html');
                    } else {
                        $('#actif_secondaire_' + i + ' .actif_secondaire_h3').html(response.result[i].marque_titre);
                        $('#actif_secondaire_' + i + ' .actif_secondaire_txt').html(response.result[i].marque_desc);
                        $('#actif_secondaire_' + i + ' img').attr('src', path_relative_root + 'img/marque/' + response.result[i].marque_id + '.jpg');
                        $('#actif_secondaire_' + i + ' a').attr('href', path_relative_root + 'nos-plantes-et-actifs/' + response.result[i].marque_path + '.html');
                    }
                }

                if (response.nb_actifs == 1) {
                    $('#bloc_actif_secondaire').hide();
                } else if (response.nb_actifs == 2) {
                    $('#actif_secondaire_2').hide();
                }

            } else {
                $('.actifs').hide();
            }
        }
    });
}


/**
 * Call to get batch product based on selection
 **/
function onItemProdChange(prod_choice_id= '', choice_prod_type = '') {
    // Initialize variables
    let choice1 = '';
    let choice2 = '';
    //Get product_choice id for batchch distinct product
    if (choice_prod_type && prod_choice_id) {
        if ( choice_prod_type === 'prod1') {  // first choice product
            choice1 = $('input[name="prod_choice_' + prod_choice_id + '"]').val();
            choice2 = $('#prod2').val();
            if (choice1 == choice2) {
                let options = [];
                // Loop through each input with class 'prod1'
                $('input.prod2').each(function() {
                    options.push($(this).val());
                });
                choice2 = options[0];
            }
        }
        if (choice_prod_type === 'prod2') {  // second choice product
            choice1 = $('#prod1').val();
            choice2 = $('input[name="prod_choice_' + prod_choice_id + '"]').val();
            if (choice1 == choice2) {
                let options = [];
                // Loop through each input with class 'prod1'
                $('input.prod1').each(function() {
                    options.push($(this).val());
                });
                choice1 = options[0];
            }
        }
    }

    if (choice1 && choice2) {
        let data = {
            prod1: choice1,
            prod2: choice2,
            head_ref: $('input[name="head_ref"]').val(),
            json: 'json'
        };

        $.ajax({
            url: path_relative_root + 'ajax_distinct_batch_data.php',
            type: 'get',
            async: true, // Should be async but would then need a loader so...
            dataType: 'json',
            data: data,
            success: function (res) {
                if (res != undefined && res != '' && res != null){
                    const inputElement = document.getElementById('batch_ref');
                    // Check if the element exists
                    if (inputElement) {
                        inputElement.value = res;
                    }
                }
            }
        });
    }

}

/**
 * Call to get batch product based on selection
 **/
function loadBatchProduct () {
    let batch_product_id = '';
    // Get batch product id for display on view

    const batchRefInput = document.getElementById('batch_ref');
    if (batchRefInput) {
         batch_product_id = batchRefInput.value;
    }
    // Get current URL
    if (batch_product_id != '' && batch_product_id != null && batch_product_id != undefined) {
        const currentUrl = window.location.href;
        const id = batch_product_id;
        const regex = /-(\d+)\.html$/;
        const newUrl = currentUrl.replace(regex, `-${id}.html`);
        //redirect to selected bacth
        window.location.href = newUrl;
    }
}


//Initialize swiper from reloadLeftAndRightTunnel function on app
$(window).on("basketToWish_success", function (data) {
    // load new cartAssocprod and init swiper
    var cartAssocProd = $('.cartAssocProd');
    if (cartAssocProd.length > 0) {
        var loadedCartAssocProd = $('.cartAssocProd', res);
        if (loadedCartAssocProd.length) {
            cartAssocProd.html(loadedCartAssocProd.html());
            if ($('#cart_slider_wrapper').length) {
                new Swiper('#cart_slider_wrapper', {
                    slidesPerView: 2,
                    spaceBetween: 25,
                    navigation: {
                        nextEl: '.netreviews-button-next',
                        prevEl: '.netreviews-button-prev',
                    },
                });
            }
        }
    }
});
